import "./HeaderSkeleton.css";

const HeaderSkeleton = () => {
    return(
        <div className="restos-header-skeleton-container">
            <div className="header-picture skeleton light-gray"></div>
            <div className="header-title-up skeleton light-gray"></div>
            <div className="header-title-down skeleton light-gray"></div>
        </div>
    )
}

export default HeaderSkeleton;