import { toast } from "react-toastify";
import { getSvg } from "./getSvg";
import CloseIcon from '@mui/icons-material/Close';

export const handleNotification = (notification, selectedRestaurant, getMessages, getUnreadMessages, handleNotificationClick, currentRestaurantModeIconsStyle, location) => {

    const clickAction = notification.data.clickAction;

    // Determine the URL based on the environment
    const url = process.env.NODE_ENV === "development"
        ? clickAction.substring(clickAction.indexOf("/", clickAction.indexOf("//") + 2))
        : clickAction;

    let currentPath, restaurantId, branchId;
    // Check if /messages is included in the clickAction
    if (url.includes('/messages')) {
        // Extract the restaurantId and branchId if /messages is included in the clickAction
        const messagesPattern = /\/([^\/]+)\/messages\/([^\/]*)/;
        const match = url.match(messagesPattern);

        if (match) {
            restaurantId = match[1];
            branchId = match[2];
            currentPath = location.pathname;

            if (restaurantId === selectedRestaurant) {
                if (branchId && currentPath.includes(`/messages/${branchId}`)) {
                    // Call getMessages API for that branch
                    getMessages(selectedRestaurant, branchId);
                } else {
                    // Call getUnreadMessages API
                    getUnreadMessages(selectedRestaurant)
                }
            }
        }
        // Show toast notification if the current path contains /messages and the restaurantId matches but branchId does not match

        if (!(currentPath.includes(`${restaurantId}/messages/${branchId}`) && url.includes('/messages'))) {
            toast.info(
                <div
                    className="notification-container"
                    onClick={() => handleNotificationClick(url)}
                >
                    <div className="notification-icon-title">
                        <div className="notification-icon-container">
                            {getSvg(
                                `infoIcon${currentRestaurantModeIconsStyle}`,
                                { color: "var(--icons-color-over-secondary-color)" },
                                { width: "22px", height: "22px" }
                            )}
                        </div>
                        <div className="notification-title">
                            {notification?.notification?.title}
                        </div>
                    </div>
                    <div className="notification-body">
                        {notification?.notification?.body}
                    </div>
                    {notification?.notification?.image && (
                        <div className="notification-image-container">
                            <img src={notification?.notification?.image} alt="Notification" />
                        </div>
                    )}
                </div>,
                {
                    autoClose: false,
                    hideProgressBar: true,
                    icon: false,
                    className: "custom-toast-container",
                    closeButton: <CloseIcon style={{ color: "var(--icons-color-over-secondary-color)", fontSize: "18px" }} />
                }
            );
        }
    }
}