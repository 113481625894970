import { getRGBValues, getWarmAndColdColors, rgbToValues } from "../Common";

export const setDesignDefaultValues = (language, currentRestaurantDesign, selectedTheme, setupData) => {
    let currentThemeFontFamily = language === "arabic" ? currentRestaurantDesign?.fonts?.arabicFont : currentRestaurantDesign?.fonts?.mainFont;
    let currentDefaultFontFamily = (!selectedTheme || selectedTheme === "light-mode") ? language === "arabic" ? setupData[0]?.fonts?.arabicFont : setupData[0]?.fonts?.mainFont : language === "arabic" ? setupData[1]?.fonts?.arabicFont : setupData[1]?.fonts?.mainFont;

    const currentFontFamily = currentThemeFontFamily || currentDefaultFontFamily;
    //set main font name value
    document.documentElement.style.setProperty(
        "--main-font",
        currentFontFamily
    );

    //set font variables values
    if (currentFontFamily === "Segoe UI") {
        document.documentElement.style.setProperty(
            "--font-regular",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Regular, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-italic",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Italic, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-semibold",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Semibold, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-bold",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Bold, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-light",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Light, sans-serif"
        );
    } else {
        document.documentElement.style.setProperty(
            "--font-regular",
            `normal 400 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-italic",
            `italic 400 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-semibold",
            `normal 600 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-bold",
            `normal 700 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-light",
            `normal 300 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
    }
}

export const setFontValues = (mainDesign, setupData, currentRestaurantDesign, selectedTheme, language) => {

    let currentThemeMainDesignData = !mainDesign || mainDesign === null || mainDesign?.length === 0 ? setupData[!currentRestaurantDesign || currentRestaurantDesign?.length === 1 || selectedTheme === "light-mode" ? 0 : 1] : (mainDesign?.designSetup?.length === 1 || selectedTheme === "light-mode") ? mainDesign?.designSetup[0] : mainDesign?.designSetup[1];

    let currentThemeFontFamily = language === "arabic" ? currentThemeMainDesignData?.fonts?.arabicFont : currentThemeMainDesignData?.fonts?.mainFont;

    let currentDefaultFontFamily = (!selectedTheme || selectedTheme === "light-mode") ? language === "arabic" ? setupData[0]?.fonts?.arabicFont : setupData[0]?.fonts?.mainFont : language === "arabic" ? setupData[1]?.fonts?.arabicFont : setupData[1]?.fonts?.mainFont;

    const currentFontFamily = currentThemeFontFamily || currentDefaultFontFamily;

    if (currentFontFamily === "Segoe UI") {
        document.documentElement.style.setProperty(
            "--font-regular-main-design",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Regular, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-italic-main-design",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Italic, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-semibold-main-design",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Semibold, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-bold-main-design",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Bold, sans-serif"
        );
        document.documentElement.style.setProperty(
            "--font-light-main-design",
            "normal 400 var(--font-sixteen)/1.3 Segoe UI Light, sans-serif"
        );
    } else {
        document.documentElement.style.setProperty(
            "--font-regular-main-design",
            `normal 400 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-italic-main-design",
            `italic 400 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-semibold-main-design",
            `normal 600 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-bold-main-design",
            `normal 700 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
        document.documentElement.style.setProperty(
            "--font-light-main-design",
            `normal 300 var(--font-sixteen)/1.3 ${currentFontFamily}, sans-serif`
        );
    }
}

export const setLoginDesignValues = (loginDesignSetup) => {
    //login bg image
    //login bg image url
    document.documentElement.style.setProperty("--login-bg-image-url", `url("${loginDesignSetup?.background?.image}")` || "");

    //login bg image opacity
    document.documentElement.style.setProperty("--login-bg-image-opacity", loginDesignSetup?.background?.opacity || 50);

    //login bg image repeat
    document.documentElement.style.setProperty("--login-bg-image-repeat", loginDesignSetup?.background?.type && loginDesignSetup?.background?.type === "repeat" ? "repeat" : "no-repeat");

    //login bg image size
    document.documentElement.style.setProperty("--login-bg-image-size", loginDesignSetup?.background?.type && loginDesignSetup?.background?.type === "repeat" ? "contain" : "cover");

    //login bg image position
    document.documentElement.style.setProperty("--login-bg-image-position", loginDesignSetup?.background?.type && loginDesignSetup?.background?.type === "cover" ? "center" : "initial");

    //login bg color
    document.documentElement.style.setProperty("--login-background-color", loginDesignSetup?.backgroundColor || "#202020");

    //set login bg color rgb values
    const loginBackgroundColorRgbValues = getRGBValues(loginDesignSetup?.backgroundColor);
    if (loginBackgroundColorRgbValues) {
        document.documentElement.style.setProperty("--login-background-color-rgb-values", loginBackgroundColorRgbValues);
    }
}

export const setDesignValues = (currentRestaurantDesign, selectedTheme, setupData, language) => {

    let currentThemeData = currentRestaurantDesign;
    let currentDefaultSetupData = (!selectedTheme || selectedTheme === "light-mode") ? setupData[0] : setupData[1];

    //bg image
    //bg image url
    document.documentElement.style.setProperty("--bg-image-url", `url("${currentThemeData?.background?.image}")` || "");

    //bg image opacity
    document.documentElement.style.setProperty("--bg-image-opacity", currentThemeData?.background?.opacity || 50);

    //bg image repeat
    document.documentElement.style.setProperty("--bg-image-repeat", currentThemeData?.background?.type && currentThemeData?.background?.type === "repeat" ? "repeat" : "no-repeat");

    //bg image size
    document.documentElement.style.setProperty("--bg-image-size", currentThemeData?.background?.type && currentThemeData?.background?.type === "repeat" ? "contain" : "cover");

    //colors
    //set main color r, g, b values
    const mainColorRgbValues = getRGBValues(currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    if (mainColorRgbValues) {
        document.documentElement.style.setProperty("--main-color-rgb-values", mainColorRgbValues);
        document.documentElement.style.setProperty("--main-light-color", `rgba(${mainColorRgbValues}, 0.3)`);
    }
    //set main color
    document.documentElement.style.setProperty("--main-color",
        currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    //set main warm and light colors
    const warmAndLightColors = getWarmAndColdColors(currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    document.documentElement.style.setProperty("--main-color-warm", warmAndLightColors?.warmColor);
    document.documentElement.style.setProperty("--main-color-cold", warmAndLightColors?.coldColor);

    //get warm color rgb values
    const mainColorWarmRgbValues = rgbToValues(warmAndLightColors?.coldColor);
    if (mainColorWarmRgbValues) {
        //set warm color rgb values
        document.documentElement.style.setProperty("--main-color-warm-rgb-values", mainColorWarmRgbValues);
    }
    //set secondary color
    document.documentElement.style.setProperty("--secondary-color",
        currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
    const secondaryColorRgbValues = getRGBValues(currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
    if (secondaryColorRgbValues) {
        document.documentElement.style.setProperty("--secondary-color-rgb-values", secondaryColorRgbValues);
    }
    //set background color
    document.documentElement.style.setProperty("--background-color",
        currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    //set skeleton terniary color
    const warmAndLightBackgroundColors = getWarmAndColdColors(currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    document.documentElement.style.setProperty("--skeleton-terniary-color", warmAndLightBackgroundColors?.warmColor);
    //set background color rgb values
    const backgroundColorRgbValues = getRGBValues(currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    if (backgroundColorRgbValues) {
        document.documentElement.style.setProperty("--background-color-rgb-values", backgroundColorRgbValues);
    }
    //fonts colors
    //set text color over main color
    document.documentElement.style.setProperty("--text-color-over-main-color",
        currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    //set light text color over main color
    const textColorOverMainColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    if (textColorOverMainColorRgbValues) {
        document.documentElement.style.setProperty("--light-text-color-over-main-color", `rgba(${textColorOverMainColorRgbValues}, 0.6)`);
    }
    //set cold text color over main color
    const mainColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    document.documentElement.style.setProperty("--cold-text-color-over-main-color",
        mainColdAndWarmTextColors?.coldColor);
    //set text color over secondary color
    document.documentElement.style.setProperty("--text-color-over-secondary-color",
        currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    //set light text color over secondary color
    const textColorOverSecondaryColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    if (textColorOverSecondaryColorRgbValues) {
        document.documentElement.style.setProperty("--text-color-over-secondary-color-rgb-values", textColorOverSecondaryColorRgbValues);
        document.documentElement.style.setProperty("--light-text-color-over-secondary-color", `rgba(${textColorOverSecondaryColorRgbValues}, 0.4)`);
    }
    //set cold text color over secondary color
    const secondaryColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    document.documentElement.style.setProperty("--cold-text-color-over-secondary-color",
        secondaryColdAndWarmTextColors?.coldColor);
    //set text color over background color
    document.documentElement.style.setProperty("--text-color-over-background-color",
        currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    //set text color over background color r, g, b values
    const textColorOverBackgroundColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    if (textColorOverBackgroundColorRgbValues) {
        document.documentElement.style.setProperty("--text-color-over-background-color-rgb-values", textColorOverBackgroundColorRgbValues);
        document.documentElement.style.setProperty("--light-text-color-over-background-color", `rgba(${textColorOverBackgroundColorRgbValues}, 0.4`);
    }
    //set cold text color over background color
    const backgroundColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    document.documentElement.style.setProperty("--cold-text-color-over-background-color",
        backgroundColdAndWarmTextColors?.coldColor);
    //set text color over overlay color
    document.documentElement.style.setProperty("--text-color-over-overlay",
        currentThemeData?.colors?.textColorOverOverlay || currentDefaultSetupData?.textColorOverOverlay);
    const textColorOverOverlayRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverOverlay || currentDefaultSetupData?.textColorOverOverlay);
    if (textColorOverOverlayRgbValues) {
        document.documentElement.style.setProperty("--light-text-color-over-overlay", `rgba(${textColorOverOverlayRgbValues}, 0.6)`);
    }
    //icons colors
    //set icons color over main color
    document.documentElement.style.setProperty("--icons-color-over-main-color",
        currentThemeData?.colors?.iconsColorOverMainColor || currentDefaultSetupData?.iconsColorOverMainColor);
    //set icons color over secondary color
    document.documentElement.style.setProperty("--icons-color-over-secondary-color",
        currentThemeData?.colors?.iconsColorOverSecondaryColor || currentDefaultSetupData?.iconsColorOverSecondaryColor);
    //set icons color over background color
    document.documentElement.style.setProperty("--icons-color-over-background-color",
        currentThemeData?.colors?.iconsColorOverbackgroundColor || currentDefaultSetupData?.iconsColorOverBackgroundColor);
    //set main font
    let currentThemeFontFamily = language === "arabic" ? currentRestaurantDesign?.fonts?.arabicFont : currentRestaurantDesign?.fonts?.mainFont;
    let currentDefaultFontFamily = (!selectedTheme || selectedTheme === "light-mode") ? language === "arabic" ? setupData[0]?.fonts?.arabicFont : setupData[0]?.fonts?.mainFont : language === "arabic" ? setupData[1]?.fonts?.arabicFont : setupData[1]?.fonts?.mainFont;

    const currentFontFamily = currentThemeFontFamily || currentDefaultFontFamily;
    //set main font name value
    document.documentElement.style.setProperty(
        "--main-font",
        currentFontFamily
    );
    document.documentElement.style.setProperty("--main-font",
        currentFontFamily);
    //set arabic font
    //set skeleton primary color
    document.documentElement.style.setProperty("--skeleton-primary-color",
        currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    //set skeleton secondary color
    document.documentElement.style.setProperty("--skeleton-secondary-color",
        currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
}

export const setMainDesignValues = (mainDesign, selectedTheme, setupData) => {
    let currentThemeData = (mainDesign?.designSetup?.length === 1 || selectedTheme === "light-mode") ? mainDesign?.designSetup[0] : mainDesign?.designSetup[1]
    let currentDefaultSetupData = (!selectedTheme || selectedTheme === "light-mode") ? setupData[0] : setupData[1];

    //bg image
    //bg image url
    document.documentElement.style.setProperty("--bg-image-url-main-design", `url("${currentThemeData?.background?.image}")` || "");

    //bg image opacity
    document.documentElement.style.setProperty("--bg-image-opacity-main-design", currentThemeData?.background?.opacity || 50);

    //bg image repeat
    document.documentElement.style.setProperty("--bg-image-repeat-main-design", currentThemeData?.background?.type && currentThemeData?.background?.type === "repeat" ? "repeat" : "no-repeat");


    //colors
    //set main color r, g, b values
    const mainColorRgbValues = getRGBValues(currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    if (mainColorRgbValues) {
        document.documentElement.style.setProperty("--main-color-rgb-values-main-design", mainColorRgbValues);
        document.documentElement.style.setProperty("--main-light-color-main-design", `rgba(${mainColorRgbValues}, 0.3)`);
    }
    //set main color
    document.documentElement.style.setProperty("--main-color-main-design",
        currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    //set main warm and light colors
    const warmAndLightColors = getWarmAndColdColors(currentThemeData?.colors?.mainColor || currentDefaultSetupData?.mainColor);
    document.documentElement.style.setProperty("--main-color-warm-main-design", warmAndLightColors?.warmColor);
    document.documentElement.style.setProperty("--main-color-cold-main-design", warmAndLightColors?.coldColor);
    //set secondary color
    document.documentElement.style.setProperty("--secondary-color-main-design",
        currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
    const secondaryColorRgbValues = getRGBValues(currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
    if (secondaryColorRgbValues) {
        document.documentElement.style.setProperty("--secondary-color-rgb-values-main-design", secondaryColorRgbValues);
    }
    //set background color
    document.documentElement.style.setProperty("--background-color-main-design",
        currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    //set skeleton terniary color
    const warmAndLightBackgroundColors = getWarmAndColdColors(currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    document.documentElement.style.setProperty("--skeleton-terniary-color-main-design", warmAndLightBackgroundColors?.warmColor);
    //set background color rgb values
    const backgroundColorRgbValues = getRGBValues(currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    if (backgroundColorRgbValues) {
        document.documentElement.style.setProperty("--background-color-rgb-values-main-design", backgroundColorRgbValues);
    }
    //fonts colors
    //set text color over main color
    document.documentElement.style.setProperty("--text-color-over-main-color-main-design",
        currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    //set light text color over main color
    const textColorOverMainColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    if (textColorOverMainColorRgbValues) {
        document.documentElement.style.setProperty("--light-text-color-over-main-color-main-design", `rgba(${textColorOverMainColorRgbValues}, 0.6)`);
    }
    //set cold text color over main color
    const mainColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOverMainColor || currentDefaultSetupData?.textColorOverMainColor);
    document.documentElement.style.setProperty("--cold-text-color-over-main-color=main-design",
        mainColdAndWarmTextColors?.coldColor);
    //set text color over secondary color
    document.documentElement.style.setProperty("--text-color-over-secondary-color-main-design",
        currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    //set light text color over secondary color
    const textColorOverSecondaryColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    if (textColorOverSecondaryColorRgbValues) {
        document.documentElement.style.setProperty("--text-color-over-secondary-color-rgb-values-main-design", textColorOverSecondaryColorRgbValues);
        document.documentElement.style.setProperty("--light-text-color-over-secondary-color-main-design", `rgba(${textColorOverSecondaryColorRgbValues}, 0.4)`);
    }
    //set cold text color over secondary color
    const secondaryColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOversecondaryColor || currentDefaultSetupData?.textColorOverSecondaryColor);
    document.documentElement.style.setProperty("--cold-text-color-over-secondary-color-main-design",
        secondaryColdAndWarmTextColors?.coldColor);
    //set text color over background color
    document.documentElement.style.setProperty("--text-color-over-background-color-main-design",
        currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    //set text color over background color r, g, b values
    const textColorOverBackgroundColorRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    if (textColorOverBackgroundColorRgbValues) {
        document.documentElement.style.setProperty("--text-color-over-background-color-rgb-values-main-design", textColorOverBackgroundColorRgbValues);
        document.documentElement.style.setProperty("--light-text-color-over-background-color-main-design", `rgba(${textColorOverBackgroundColorRgbValues}, 0.4`);
    }
    //set cold text color over background color
    const backgroundColdAndWarmTextColors = getWarmAndColdColors(currentThemeData?.colors?.textColorOverBackgroundColor || currentDefaultSetupData?.textColorOverBackgroundColor);
    document.documentElement.style.setProperty("--cold-text-color-over-background-color-main-design",
        backgroundColdAndWarmTextColors?.coldColor);
    //set text color over overlay color
    document.documentElement.style.setProperty("--text-color-over-overlay-main-design",
        currentThemeData?.colors?.textColorOverOverlay || currentDefaultSetupData?.textColorOverOverlay);
    const textColorOverOverlayRgbValues = getRGBValues(currentThemeData?.colors?.textColorOverOverlay || currentDefaultSetupData?.textColorOverOverlay);
    if (textColorOverOverlayRgbValues) {
        document.documentElement.style.setProperty("--light-text-color-over-overlay-main-design", `rgba(${textColorOverOverlayRgbValues}, 0.6)`);
    }
    //icons colors
    //set icons color over main color
    document.documentElement.style.setProperty("--icons-color-over-main-color-main-design",
        currentThemeData?.colors?.iconsColorOverMainColor || currentDefaultSetupData?.iconsColorOverMainColor);
    //set icons color over secondary color
    document.documentElement.style.setProperty("--icons-color-over-secondary-color-main-design",
        currentThemeData?.colors?.iconsColorOverSecondaryColor || currentDefaultSetupData?.iconsColorOverSecondaryColor);
    //set icons color over background color
    document.documentElement.style.setProperty("--icons-color-over-background-color-main-design",
        currentThemeData?.colors?.iconsColorOverbackgroundColor || currentDefaultSetupData?.iconsColorOverBackgroundColor);
    //set skeleton primary color
    document.documentElement.style.setProperty
        ("--skeleton-primary-color-main-design",
            currentThemeData?.colors?.backgroundColor || currentDefaultSetupData?.backgroundColor);
    //set skeleton secondary color
    document.documentElement.style.setProperty("--skeleton-secondary-color-main-design",
        currentThemeData?.colors?.secondaryColor || currentDefaultSetupData?.secondaryColor);
}

export const updateMetaImage = (logo) => {
    const metaTags = document.getElementsByTagName("meta");

    // Update Open Graph Protocol (OGP) image
    const ogImageTag = Array.from(metaTags).find(
        (tag) => tag.getAttribute("property") === "og:image"
    );
    if (ogImageTag) {
        ogImageTag.setAttribute("content", logo);
    }

    // Update Twitter image
    const twitterImageTag = Array.from(metaTags).find(
        (tag) => tag.getAttribute("name") === "twitter:image"
    );
    if (twitterImageTag) {
        twitterImageTag.setAttribute("content", logo);
    }

    // Get the existing meta tag with the name "thumbnail"
    const existingMetaTag = document.querySelector('meta[name="thumbnail"]');

    // Create a new meta tag element
    const newMetaTag = document.createElement("meta");
    newMetaTag.setAttribute("name", "thumbnail");
    newMetaTag.setAttribute("content", logo);

    if (existingMetaTag) {
        // If the meta tag already exists, replace it with the new one
        existingMetaTag.parentNode.replaceChild(newMetaTag, existingMetaTag);
    } else {
        // If the meta tag doesn't exist, add the new one to the document's head
        document.head.appendChild(newMetaTag);
    }
}