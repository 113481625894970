import RestaurantSkeleton from "./RestaurantSkeleton/RestaurantSkeleton";
import "./RestaurantsListSkeleton.css";

const RestaurantsListSkeleton = () => {
    return (
        <div className="restos-list-skeleton-container">
            <RestaurantSkeleton/>
            <RestaurantSkeleton/>
            <RestaurantSkeleton/>
            <RestaurantSkeleton/>
        </div>
    )
}

export default RestaurantsListSkeleton;