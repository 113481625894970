import HeaderSkeleton from "../../Restaurants/HeaderSkeleton/HeaderSkeleton";
import RestaurantsListSkeleton from "../../Restaurants/RestaurantsSkeleton/RestaurantsListSkeleton";
import "./RestaurantsSkeleton.css";

const RestaurantsSkeleton = () => {
    return (
        <div className="restos-skeleton-page-wrapper">
        <HeaderSkeleton/>
        <RestaurantsListSkeleton/>
        </div>
    )
}

export default RestaurantsSkeleton;