import "./RestaurantSkeleton.css";

const RestaurantSkeleton = () => {
    return (
        <div className="resto-skeleton-card-container">
            <div className="card-image skeleton main-design light-gray"></div>
            <div className="resto-skeleton-card-name skeleton main-design light-gray"></div>
            <div className="resto-skeleton-card-desc skeleton main-design light-gray"></div> 
        </div>
    )
}

export default RestaurantSkeleton;
