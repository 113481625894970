export const setupData = [
    {
        mode: "light-mode",
        mainColor: "#83BD37",
        secondaryColor: "#FFFFFF",
        backgroundColor: "#F2F2F2",
        textColorOverMainColor: "#FFFFFF",
        textColorOverSecondaryColor: "#000000",
        textColorOverBackgroundColor: "#000000",
        textColorOverOverlay: "#FFFFFF",
        iconsColorOverMainColor: "#FFFFFF",
        iconsColorOverSecondaryColor: "#000000",
        iconsColorOverBackgroundColor: "#000000",
        modeCustomizedByUser: false,
        mainFont: "Segoe UI",
        arabicFont: "Segoe UI",
        iconsStyle: "fullColorIcons"
    },
    {
        mode: "dark-mode",
        mainColor: "#83BD37",
        secondaryColor: "#363636",
        backgroundColor: "#222222",
        textColorOverMainColor: "#FFFFFF",
        textColorOverSecondaryColor: "#FFFFFF",
        textColorOverBackgroundColor: "#FFFFFF",
        textColorOverOverlay: "#FFFFFF",
        iconsColorOverMainColor: "#FFFFFF",
        iconsColorOverSecondaryColor: "#FFFFFF",
        iconsColorOverBackgroundColor: "#FFFFFF",
        modeCustomizedByUser: false,
        mainFont: "Segoe UI",
        arabicFont: "Segoe UI",
        iconsStyle: "fullColorIcons"
    },
]